<template>
	<div v-bind:style="alertStyle(color)" class="d-flex">
		<div class=" mr-2">
			<i v-bind:class="icon" v-if="icon != ''"></i>
		</div>
		<div class="d-flex">
			<strong v-if="title" class="mr-2">{{title}}</strong>
			<div><slot></slot></div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Alert',
		props: {
			title: '',
			icon: '',
			color: '',
			fill:false
		},

		data (){
			return {}
		},

		methods:{
			alertStyle(color){
				if(this.fill) {
					return {
						'background-color': 'var(--'+color+'-rj)',
						'color':'#fff',
						'border': '1px solid var(--'+color+'-rj)',
						'border-radius': '0.25rem',
						'padding': '10px'
					}
				} else {
					return {
						'color': 'var(--'+color+'-rj)',
						'border': '1px solid var(--'+color+'-rj)',
						'border-radius': '0.25rem',
						'padding': '10px'
					}
				}

			}
		}
	}
</script>