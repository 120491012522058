<template>
	<div class="mt-5">
		<img v-bind:src="qrCodeLogin" width="300px" id="qrcode_uid" />
	</div>
</template>
<script>
	import QRCode from 'qrcode';
	import * as MainServices from '@/services/MainServices.js'
	export default {

		name: "LoginQR",
		data(){
			return {
				uid: "zIYarLzOOeMBwmdubKNXNvFSOIN2",
				secret: "XEhrWAL3bPPHngbbzA6EqNeiak33",
				qrCodeLogin: null,
				appLink: 'https://play.google.com/store/apps/details?id=com.robinfood.motoboy&hl=pt_BR'
			}
		},

		mounted(){
			let self = this;			
			var opts = {
				errorCorrectionLevel: 'H',
				type: 'image/png',
				quality: 1,
				margin: 1,
			};
			if(this.uid && this.secret) {
				QRCode.toDataURL(`[rfrastreio]${this.uid}:${this.secret}`, opts, function (error, url) {
					if(error){
						Utils.showDialogError("Desculpe, ocorreu um erro durante a geração do QR Code, por favor tente novamente ou entre em contato com nosso suporte.");
					}
					self.$set(self, "qrCodeLogin", url);
				})
			}	
		},
	}
</script>