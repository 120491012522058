<template>
	<Modal ref="Modal" :hideCallback="clear">
		<template v-slot:header>
			<span><strong>{{title}}</strong></span>
			<Button class="ms-2" title="Salvar" color="green" @action="save()" :line="true" />
			<Button v-if="uid" class="ms-2" title="Apagar" color="red" @action="remove()" :line="true" />
			<Button v-if="online" class="ms-2" title="Desativar rastreio" color="orange" @action="forceOffline()" :line="true" />
		</template>
		<template v-slot:body>
			<div>
				<div class="d-flex">
					<FormInput title="Nome" class="me-1 flex-fill" v-model="name" />
					<FormInput class="ms-1 flex-fill" title="Telefone" :phone="true" v-model="phone" />
				</div>
				<div class="d-flex mt-2">
					<FormInput class="me-1 flex-fill" title="Senha" type="password" v-model="password" />
				</div>
			</div>
		</template>
	</Modal>
</template>
<script>
	import Modal from '@/components/Modal'
	import FormInput from '@/components/FormInput'
	import * as DriverServices from '@/services/DriverServices.js'
	import Button from '@/components/Button'

	export default {
		name: "ModalNewDriver",
		components: { Modal, FormInput, Button },

		data(){
			return {
				name: null,
				phone: null,
				password: null,
				uid: null,
				originalPhone: null,
				title: "Novo Colaborador",
				online: false
			}
		},
		methods:{
			showModal(driver){
				let self = this;
				if(driver != null) {
					this.name = driver.full_name;
					this.phone = Utils.formatPhone(driver.phone);
					this.originalPhone = driver.phone;
					this.password = driver.password;
					this.uid = driver.uid;
					this.title = "Editar Colaborador";
					this.online = driver.online;
				}
				return new Promise((resolve, reject) =>{
					self.$refs.Modal.show();
				})
			},
			clear(){
				return new Promise((resolve, reject)=>{
					this.name = null;
					this.phone = null;
					this.password = null;
					this.uid = null;
					this.originalPhone = null;
					this.title = "Novo Colaborador"
					resolve();
				})
				
			},
			save(){
				let self = this;
				const phone = this.phone.replace(/\W+/g, '');
				this.$refs.Modal.showLoading();
				DriverServices.setDriver(
					this.name,
					this.password,
					phone,
					this.uid,
					this.originalPhone)
				.then((message)=>{
					self.$refs.Modal.closeLoading();
					self.$refs.Modal.hide();
					Utils.showSnackbarSuccess(message);
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showSnackbarError(err);
				})
			},
			remove(){
				let self = this;
				Utils.showDialogConfirm(`Deseja mesmo remover o colaborador ${this.name}?`, 'Remover').then(()=>{
					self.$refs.Modal.showLoading();
					DriverServices.removeDriver(this.originalPhone, this.uid).then((message)=>{
						self.$refs.Modal.closeLoading();
						self.$refs.Modal.hide();
						Utils.showSnackbarSuccess(message);
					}).catch((err)=>{
						self.$refs.Modal.closeLoading();
						Utils.showSnackbarError(err);
					})
				}).catch(()=>{

				})
			},
			forceOffline(){
				let self = this;
				DriverServices.forceOffline(this.uid).then((message)=>{
					self.$refs.Modal.closeLoading();
					Utils.showSnackbarSuccess(message);
					self.online = false;
				}).catch((err)=>{
					self.$refs.Modal.closeLoading();
					Utils.showSnackbarError(err);
				})
			}
		},
	}
</script>