import { ref, set, get } from "firebase/database";
import { db, currentUser } from '@/database/firebase';

export function setDriver(name, password, phone, uid = null, oldPhone = null){
	return new Promise((resolve, reject) => {
		if(uid && oldPhone) {
			const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + oldPhone);
			get(ref(db, 'stores/' + currentUser.uid + '/driver/' + phone)).then((snapshot)=>{
				if(snapshot.exists()){
					const data = snapshot.val();
					if(data.uid != uid){
						reject("Já existe um Colaborador com esse número de telefone.");
						return;
					}
				}
				set(driverRef, null).then(()=>{
					const newDriverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
					set(newDriverRef, {
						'full_name': name.trim(),
						'password': password,
						'uid': uid
					}).then(()=>{
						resolve("Colaborador atualizado com sucesso!");
					}).catch((err)=>{
						reject("Erro ao atualizar o Colaborador.");
					});
				}).catch((err)=>{
					reject("Erro ao atualizar o Colaborador.");
				})
			})
		} else {
			const newDriverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
			get(newDriverRef).then((snapshot)=>{
				if(snapshot.exists()){
					reject("Já existe um Colaborador com esse número de telefone.");
				} else {
					const uid = Utils.generateId()
					set(newDriverRef, {
						'full_name': name.trim(),
						'password': password,
						'uid': uid
					}).then(()=>{
						set(ref(db, 'stores/' + currentUser.uid + '/locations/' + uid), {
							'online': false,
						}).then(()=>{
							resolve("Colaborador criado com sucesso!");
						}).catch((err)=>{
							reject("Erro ao criar um Colaborador.");
						});
					}).catch((err)=>{
						reject("Erro ao criar um Colaborador.");
					});
				}
			})

		}
	})
}
export function removeDriver(phone, uid){
	return new Promise((resolve, reject) => {
		const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver/' + phone);
		set(driverRef, null).then(()=>{
			const locRef = ref(db, 'stores/' + currentUser.uid + '/locations/' + uid);
			get(locRef).then((snapshot)=>{
				if(snapshot.exists()){
					set(locRef, null).then(()=>{
						resolve("Colaborador apagado com sucesso!");
					}).catch((err)=>{
						reject("Erro ao apagar o Colaborador.");
					});
				} 
			})
			resolve("Colaborador apagado com sucesso!");
		}).catch((err)=>{
			reject("Erro ao apagar o Colaborador.");
		});
	})
}

export function forceOffline(uid){
	return new Promise((resolve, reject) => {
		const locRef = ref(db, 'stores/' + currentUser.uid + '/locations/' + uid);
		get(locRef).then((snapshot)=>{
			if(snapshot.exists()){
				set(locRef, {
					'online': false,
				}).then(()=>{
					resolve("Colaborador está offline!");
				}).catch((err)=>{
					reject("Erro ao desativar o rastreio do Colaborador.");
				});
				
			} 
		})
	})
}