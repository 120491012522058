import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login/Login.vue'
import Main from '@/views/Main.vue'
import Dashboard from '@/views/Dashboard/Dashboard.vue'
import Admin from '@/views/Admin/Admin.vue'
import Accounts from '@/views/Admin/Accounts/Accounts.vue'
import Stores from '@/views/Admin/Stores/Stores.vue'
import Terms from '@/views/Landingpage/Terms.vue'
import Politic from '@/views/Landingpage/Politic.vue'
import LoginQR from '@/views/Dashboard/LoginQR.vue'
import RedirectApp from '@/views/Landingpage/RedirectApp.vue'

Vue.use(VueRouter)

const routes = [
{
    path : '/',
    name: 'main',
    component: Main,
    redirect: { name: 'dashboard' },
    
    children: [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path:'/admin',
        name:'admin',
        component: Admin,
        redirect:{name:'stores'},
        children: [
        {
            path: '/admin/accounts',
            name: 'accounts',
            component: Accounts
        },
        {
            path: '/admin/stores',
            name: 'stores',
            component: Stores
        },
        ]
    }
    ],
    
},
{
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
        title: 'Login',
        metaTags: [
        {
            name: 'X-UA-Compatible',
            content: 'ie=edge'
        },
        {
            property: 'viewport',
            content: 'width=1024'
        }
        ]
    }
},
{
    path: "/termos-de-uso",
    name: "terms",
    component: Terms,
    meta: {
        landingpage: true 
    }
},
{
    path: "/politica",
    name: "politic",
    component: Politic,
    meta: {
        landingpage: true 
    }
},
{
    path: "/loginqr",
    name: "loginqr",
    component: LoginQR,
    meta: {
        landingpage: true 
    }
},
{
    path: "/app",
    name: "redirectApp",
    component: RedirectApp,
    meta: {
        landingpage: true 
    }
}
];

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
    if(to.meta.title)
        document.title = to.meta.title;
    else
        document.title = 'Rastreio Já';

    next();

});

window._router = router;
export default router
