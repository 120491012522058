<template>
    <div class="form-input-rj">
        <strong class="form-title" v-if="title">{{ title }}</strong>
        <div class="form-group input-group emoji-picker-container">
            <div v-if="prefix" class="prefix">
                <span v-if="changeTypeNumber" style="cursor:pointer"
                    v-on:click="changeTypeInputNumber()">{{ typesOfNumber }}</span>
                <span v-else>{{ prefix }}</span>
            </div>
            <input :disabled="disable" :type="typeInput" class="form-control form-control-rj" :value="value"
                @input="handleInput" :placeholder="placeholder" @keyup.enter="updateModel()" :data="data"
                :maxlength="maxlength">
            <div v-if="sufix" class="sufix">
                <span>{{ sufixData }}</span>
            </div>
        </div>
        <div v-if="info"><small v-bind:style="infoStyle()" v-on:click="infoPressed">{{ info }}</small></div>
    </div>
</template>
<script>
export default {
    name: 'FormInput',
    props: {
        value: '',
        placeholder: '',
        title: '',
        type: '',
        value: '',
        money: false,
        phone: false,
        cnpj: false,
        cpf: false,
        prefix: null,
        sufix: null,
        maxValue: null,
        minValue: null,
        disable: {
            default: false,
            type: Boolean
        },
        info: null,
        infoClick: {
            default: null,
            type: Function
        },
        data: null,
        maxlength: null,
        changeTypeNumber: false,

    },
    computed: {
        typesOfNumber() {
            if (this.isMoney) {
                return 'R$';
            }
            return '%';
        },

    },
    data() {
        return {
            sufixData: null,
            isMoney: null,
            typeInput: 'text'
        }
    },
    mounted() {
        this.sufixData = this.sufix;
        this.isMoney = this.money;
        this.typeInput = this.type;
    },
    watch: {
        value: function () {
            this.changed();
        },
    },
    updated() {
        this.isMoney = this.money;
        this.typeInput = this.type;
    },
    methods: {
        infoPressed() {
            if (this.infoClick != null)
                this.infoClick();
        },
        infoStyle() {
            if (this.infoClick != null) {
                return {
                    'cursor': 'pointer',
                    'color': 'var(--blue-rj)'
                }
            }
            return {};
        },
        handleInput(event) {
            if (this.isMoney) {
                var value = Utils.formatMoney(event.target.value);
                if (this.maxValue != null && Utils.amountFromMoney(value) > this.maxValue) {
                    if (this.maxValue > 0)
                        value = Utils.formatMoney(this.maxValue);
                    else
                        value = Utils.formatMoney(0);
                }
                if (this.minValue != null && Utils.amountFromMoney(value) < this.minValue) {
                    if (this.minValue > 0)
                        value = Utils.formatMoney(this.minValue);
                    else
                        value = Utils.formatMoney(0);
                }
                this.$emit('input', value);
                $(event.target).val(value);
            } else if (this.phone) {
                var phone = Utils.formatPhone(event.target.value)
                this.$emit('input', phone);
                $(event.target).val(phone);
            }
            else if (this.cnpj && !this.cpf) {
                var cnpj = Utils.formatCnpj(event.target.value)
                this.$emit('input', cnpj);
                $(event.target).val(cnpj);
            }
            else if (this.cpf && !this.cnpj) {
                var cpf = Utils.formatCpf(event.target.value)
                this.$emit('input', cpf);
                $(event.target).val(cpf);
            }
            else if (this.cpf && this.cnpj) {
                var cpf = Utils.formatCnpjOrCpf(event.target.value)
                this.$emit('input', cpf);
                $(event.target).val(cpf);
            }
            else if (this.typeInput == 'number') {
                if (this.minValue != null && event.target.value <= this.minValue && event.target.value != '') {
                    event.target.value = this.minValue;
                }
                if (this.maxValue != null && event.target.value >= this.maxValue && event.target.value != '') {
                    event.target.value = this.maxValue;
                }
                this.$emit('input', event.target.value);
            }
            else {
                this.$emit('input', event.target.value);
            }
        },
        updateModel() {
            this.$emit('updateModel');
        },
        changed() {
            this.$emit('changed');
        },
        changeTypeInputNumber() {
            if (this.isMoney) {
                this.isMoney = false;
                this.typeInput = 'number';
            } else {
                this.isMoney = true;
                this.typeInput = 'text'
            }
        }
    },
}
</script>
<style>
.prefix {
    background-color: var(--gray-rj);
    padding: 0.375rem 0.75rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    color: #fff;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}

.sufix {
    background-color: var(--gray-rj);
    padding: 0.375rem 0.75rem;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    color: #fff;
    font-size: 0.8rem;
}

.form-input-rj .form-group {
    margin-bottom: 0;
}

.form-input-rj {
    text-align: start;
}
</style>