<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<span class="modal-title"><strong>Lista de pagamentos</strong></span>
		</template>
		<template v-slot:body>
			<div class="accordion" id="accordionExample" style="max-height: fit-content;">
				<div class="accordion-item" v-for="payment in payments">
					<h2 class="accordion-header">
						<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#'+payment.paymentReference" aria-expanded="true" aria-controls="collapseOne">
							<span>
								<strong>{{payment.paymentReference}}</strong>
							</span>

							<span class="ms-2 badge rounded-pill badge--green-rj" v-if="payment.paymentDate"> Pago em {{payment.paymentDate}}</span>
							<span class="ms-2 badge rounded-pill badge--red-rj" v-else>Não pago</span>
						</button>
					</h2>
					<div :id="payment.paymentReference" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
						<div class="accordion-body">
							<div class="d-flex align-items-center justify-content-between">
								<div>
									<span class="badge rounded-pill badge--green-rj">
										<strong class="me-1">Valor: </strong>
										R${{payment.valueInCents}}
									</span>
								</div>
								<div>
									<span v-if="payment.paymentDate" class="badge rounded-pill badge--green-rj">
										<strong class="me-1">Vencimento: </strong>
										{{getValidDate(payment.paymentReference)}}
									</span>
									<span v-else :class="['badge', 'rounded-pill', {'badge--red-rj': isExpired(payment.paymentReference)}, {'badge--orange-rj': !isExpired(payment.paymentReference)} ]">
										<strong class="me-1">Vencimento: </strong>
										{{getValidDate(payment.paymentReference)}}
									</span>
								</div>
								<div class="ms-2" v-if="!payment.paymentDate">
									<Button title="Pagar via PIX" color="orange" :line="true" @action="openPay(payment)" />
								</div>
							</div>
						</div>
					</div>
				</div>	
				<infinite-loading direction="end" @infinite="loadData" :identifier="listId">
					<div slot="spinner"><div class="spinner-border text-danger" role="status"></div></div>
					<div slot="no-more"><span class="font--dark-gray-robin mb-4" style="font-size:14px">Não há mais pagamentos</span></div>
					<div slot="no-results"><span class="font--dark-gray-robin mb-4" style="font-size:14px">Não encontramos nenhum pagamento</span></div>
				</infinite-loading>
			</infinite-loading>	
		</div>
	</template>
</Modal> 
</template>
<script>
	import Modal from '@/components/Modal'
	import InfiniteLoading from 'vue-infinite-loading';
	import * as MainServices from '@/services/MainServices.js'
	import Button from '@/components/Button'
	import * as PaymentUtils from '@/utils/PaymentUtils'

	export default {
		name: "ModaListPayment",
		components: { Modal, InfiniteLoading, Button },
		props: {
			uid: '',
			dueDay: '',
			lastPayment: '',
			valuePayment: '',
			createdAt: ''
		},

		data(){
			return {
				payments: [],
				nextPageToken: null,
				perPage:10,
				listId: +new Date(),
			}
		},
		methods: {
			showModal(){
				this.payments = [];
				this.nextPageToken = null;
				this.listId += 1;
				this.$refs.Modal.show();
			},
			loadData($state) {
				var self = this;
				MainServices.loadPayments(this.perPage, this.nextPageToken).then(function(result){
					if(result.length){
						self.page += 1;
						self.payments.push(...result);
						if(self.nextPageToken == null) {
							self.appendLastPayment();
						}
						self.nextPageToken = result[result.length - 1].uid;
						if($state){
							$state.loaded();
						}
					} else if(result.length != 0) {
						if($state){
							$state.loaded();
							$state.complete();
							if(self.nextPageToken == null) {
								self.appendLastPayment();
							}
						}                      
					} else {
						self.appendLastPayment();
						if($state) {
							$state.loaded();
							$state.complete();
						}
					}

				}).catch((err)=> {
					Utils.showDialogError(err);
				});
			},
			openPay(payment){
				EventBus.$emit("OPEN_MODAL_PIX");
			},
			appendLastPayment(){
				const valid = PaymentUtils.calculateValidUntil(this.dueDay, this.createdAt, this.lastPayment);
				const now = moment();
				if(this.lastPayment) {
					const lastPayment = moment(this.lastPayment);
					if(this.payments.length > 0) {
						const paymentReference = moment(this.payments[0].paymentReference, "MM/YYYY");
						if(!paymentReference.isSame(lastPayment, 'month') && !paymentReference.isSame(lastPayment, 'year')) {
							let diff = paymentReference.diff(now, 'months');
							this.appendWithDiff(diff, lastPayment);
						}
					} else {
						if(!lastPayment.isSame(now, 'month') && !lastPayment.isSame(now, 'year') && valid.isBefore(now)) {
							let diff = lastPayment.diff(now, 'month');
							this.appendWithDiff(diff, lastPayment);
						} else if(!lastPayment.isSame(now, 'month') && lastPayment.isSame(!now, 'year') && valid.isAfter(now)) {
							this.payments.unshift({
								'paymentReference':valid.format("MM/YYYY"),
								'valueInCents':this.valuePayment,
							});
						}
					}
				} else {
					let diff;
					if(valid.isAfter(now)){
						diff = valid.diff(now, 'months');
					} else {
						diff = now.diff(valid, 'months');
					}
					if(diff == 0)
						diff = 2;
					this.appendWithDiff(diff, valid.subtract(2, 'month'));
					
				}
			},
			appendWithDiff(diff, reference) {
				reference = reference.add(1, 'M');
				while (diff > 0){
					this.payments.unshift({
						'paymentReference':reference.format("MM/YYYY"),
						'valueInCents':this.valuePayment,
					});
					diff--;
					reference.add(diff, 'M');
				};
			},
			getValidDate(reference) {
				return moment({
					year: moment(reference, "MM/YYYY").year(), 
					month: moment(reference, "MM/YYYY").month(), 
					day: this.dueDay
				}).format("DD/MM/YYYY");
			},
			isExpired(date){
				return moment(this.getValidDate(date), "DD/MM/YYYY").isBefore(moment());
			}
			
		}
	}
</script>