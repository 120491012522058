<template></template>
<script>
	export default {
		name: "RedirectApp",
		mounted(){
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;

			if (/android/i.test(userAgent)) {
				window.location.replace("https://play.google.com/store/apps/details?id=br.com.robinfood.rastreio");
			} else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				window.location.replace("https://apps.apple.com/us/app/rastreio-j%C3%A1-colaboradores/id6736658021");
			} else {
				window.location.replace("https://rastreioja.com.br");
			}			
		}
	}
</script>