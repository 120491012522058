<template>
	<div class="container-login">
		<div class="d-flex h-100 align-items-center">
			<div class="container-login-form flex-fill">
				<div class="logo d-flex flex-column justify-content-center w-100">
					<div class="text-center">
						<img :src="logo" style="width: 30rem">
					</div>
					<transition name="fade" key="login" mode="out-in">
						<div>
							<div class="form-login">
								<Alert v-if="error" title="Opss!" color="red" icon="las la-exclamation-circle fa-lg" class="mb-3">
									<div style="word-break:break-word;">
										{{error}}
									</div>
								</Alert>
								<FormInput title="Usuário" class="mb-4" type="email" v-model="email" />
								<FormInput title="Senha" type="password" v-model="password" @updateModel="login()" />
							</div>
							<div class="container-button-login">
								<Button title="Login" color="blue" class="button-login" :line="true" @action="login()" />
							</div>
						</div>
					</transition>
				</div>
			</div>
			<div class="login-wallpaper flex-fill desktop-only">
				<img :src="loginWallpaper" style="width: 100%;">
			</div>
		</div>
	</div>
</template>
<script>
	import * as LoginServices from '@/services/LoginServices.js'
	import FormInput from '@/components/FormInput'
	import Button from '@/components/Button'
	import _loginWallpaper from '@/assets/images/banner.webp'
	import Alert from '@/components/Alert.vue'
	import logo from '@/assets/images/rj_logo_2.png'

	export default {
		name: "Login",
		components: { FormInput, Button, Alert },

		data: () => {
			return {
				email:null,
				password:null,
				messageError: null,
				loginWallpaper: _loginWallpaper,
				error: null,
				logo: logo
			}
		},
		mounted(){
			
		},
		methods: {
			login() {
				var self = this;
				Utils.showLoading('.container-login');
				LoginServices.signIn(this.email, this.password)
				.then(function(){
					Utils.closeLoading('.container-login');
					self.error = null;
				})
				.catch(function(err){
					Utils.closeLoading('.container-login');
					self.error = "Usuário ou senha incorretos"
				})
			},
		}
	}
</script>
<style scoped>
	.container-login {
		background-color: #FFF;
		height: 100%;
		color: #3E404E;
	}
	.container-login-form {
		min-width: 40%;
		max-width: 40%;
		height: 100%;
		display: flex;
		padding: 1.4rem;
	}
	.form-login {
		padding-left: 5rem;
		padding-right: 5rem;
		margin-top: 3rem;
	}
	.container-button-login {
		padding-left: 5rem;
		padding-right: 5rem;
		margin-top: 3rem;
	}
	.button-login {
		width: 100%;
		padding: .5rem;
		color: #471222!important;
		font-weight: bold;
		border: 1px solid #471222;
	}
	.button-login:hover {
		width: 100%;
		padding: .5rem;
		color: #FFF!important;
		background-color: #471222!important;
		font-weight: bold;
		border: 1px solid #471222;
	}
	.button-login >>> .button--title {
		width: 100%;
		text-align: center;
	}
	.form-login >>> .form-input-rj .form-title {
		font-weight: normal!important;
	}
	.line-left {
		background-image: linear-gradient(to right, transparent, #4242426e, #4242426e);
		width: 250px;
		height: 1px;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	}
	.line-right {
		background-image: linear-gradient(to left, transparent, #4242426e, #4242426e);
		width: 250px;
		height: 1px;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	}
	.login-wallpaper {
		height: 100%;
		display: flex;
	}
	.login-wallpaper img {
		height: auto;
		object-fit: cover;
		width: 100%;
	}

	@media (max-width: 768px) {
		.container-login-form{
			max-width: 100%;
			background-image: url('/src/assets/images/banner.webp');
			background-size: cover;
		}

		.container-login-form .form-login{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		.container-login-form .container-button-login {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		.container-login-form > div {
			background: #ffffffe0;
			height: fit-content;
			margin-top: auto;
			margin-bottom: auto;
			padding: 20px;
			border-radius: 8px;
		}
		.container-login-form .logo img {
			width: -webkit-fill-available!important;
		}

	}
</style>