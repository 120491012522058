<template>
	<Modal ref="Modal">
		<template v-slot:header>
			<h2><strong>Fazer Login Como Colaborador</strong></h2>
		</template>
		<template v-slot:body>
			<div>
				<div class="d-flex mt-4 flex-column">
					<div class="step_container card-rj">
						<div class="step_number">1</div>
						<div class="step_info">
							<span>Para começar, o colaborador deve baixar o <strong>app de rastreamento</strong> acessando o link <a href="https://www.rastreioja.com.br/app" target="_blank">www.rastreioja.com.br/app</a> <button id="button-copy" class="btn " title="Copiar" data-bs-toggle="tooltip" v-on:click="copyUrl('https://www.rastreioja.com.br/app', 'button-copy')"><i class="fa-regular fa-copy"></i></button> ou acessando o QR code ao lado:</span>
						</div>
						<img v-bind:src="qrCodeStore" width="100px" class="ms-auto" id="qrcode_store" />
					</div>
					<div class="step_container card-rj">
						<div class="step_number">2</div>
						<div class="step_info">Na tela inicial, o colaborador deverá <strong>habilitar todas as permissões solicitadas.</strong></div>
					</div>
					<div class="step_container card-rj">
							<div class="step_number">3</div>
							<div class="step_info">Em seguida, quando solicitado, o colaborador deverá escanear o QR Code ao lado:</div>
							<img v-bind:src="qrCodeLogin" width="100px" class="ms-auto" id="qrcode_uid" />
							
						</div>				
					<div class="step_container card-rj">
						<div class="step_number">4</div>
						<div class="step_info">No formulário de login do app, o colaborador deverá preencher os campos de <strong>telefone</strong> e <strong>senha</strong> de acordo com o que foi cadastrado no sistema pela loja.</div>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>
<script>
	import Modal from '@/components/Modal';
	import QRCode from 'qrcode';

	export default {
		name: "ModalLoginDriver",
		components: { Modal },
		props: {
			secret: '',
			uid: '',
		},

		data(){
			return {
				qrCodeStore: null,
				qrCodeLogin: null,
				appLink: 'https://www.rastreioja.com.br/app'
			}
		},
		methods: {
			showModal(){
				let self = this;
				var opts = {
					errorCorrectionLevel: 'H',
					type: 'image/png',
					quality: 1,
					margin: 1,
				};
				QRCode.toDataURL(this.appLink, opts, function (error, url) {
					if(error){
						Utils.showDialogError("Desculpe, ocorreu um erro durante a geração do QR Code, por favor tente novamente ou entre em contato com nosso suporte.");
					}
					self.$set(self, "qrCodeStore", url);
				})
				QRCode.toDataURL(`[rfrastreio]${this.uid}:${this.secret}`, opts, function (error, url) {
					if(error){
						Utils.showDialogError("Desculpe, ocorreu um erro durante a geração do QR Code, por favor tente novamente ou entre em contato com nosso suporte.");
					}
					self.$set(self, "qrCodeLogin", url);
				})
				return new Promise((resolve, reject) =>{
					self.$refs.Modal.show();
				})
			},
			copyUrl(url, id) {
				Utils.copyCode(url, id);
			}
		}
	}
</script>
