<template>
	<Transition>
		<div v-bind:id="modal_id_" class="modal-rj" v-show="showing" v-on:click.self="hide()">
			<div class="modal-dialog-rj" v-bind:class="{'full-modal':full && !small, 'small-modal': small && !full}">
				<div class="modal-content-rj" :style="contentStyle">
					<div class="texture_container" :class="'texture-'+textureColor" v-if="textureColor!=null"></div>
					<div class="d-flex align-items-center modal-header-rj" v-if="hasHeader()">
						<div><slot name="header"></slot></div>
					</div>
					<div class="modal-body-rj">
						<div><slot name="body"></slot></div>
					</div>
					<div class="modal-footer-rj" v-if="hasFooter()">
						<div><slot name="footer"></slot></div>
					</div>
					<div class="position-absolute" style="right:0;top:0">
						<div class="button-close-modal">
							<button type="button" class="btn-close" aria-label="Close" v-on:click="hide()">
								<span aria-hidden="true"><i class="las la-times"></i></span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>
<script>
	export default {
		name:'Modal',
		props: {
			hideCallback: {
			default: null,
				type: Function
			},
			full: {
				type: Boolean,
			default: false
			},
			small: {
				type: Boolean,
			default: false
			},
			textureColor: {
				type: String,
			default: null
			}
		},
		computed : {
			contentStyle: function() {
				if(this.textureColor == 'white') {
					return {
						'background-color':'#fff',
						'color':'#54206A'
					};
				} else if(this.textureColor == 'red') {
					return {
						'background-color':'var(--red-rj)',
						'color':'#fff'
					};
				} else if(this.textureColor == 'orange') {
					return {
						'background':'linear-gradient(to right bottom, var(--orange-rj), var(--red-rj))',
						'color':'#fff',
					};
				}
				return {

				};
			}
		},
		data() {
			return {
				modal_id_ : Utils.generateId(),
				showing:false,
			}
		},
		mounted(){
			var self = this;
			$(document).keyup(function(ev){
				if(ev.keyCode == 27) {
					if(self.showing)
						self.hide();
				}
			})
		},
		methods: {
			hasHeader() {
				return this.$slots.header;
			},
			hasFooter() {
				return this.$slots.footer;
			},
			show() {
				this.showing = true;
			},
			hide() {
				var self = this;
				if(this.hideCallback){
					this.hideCallback()
					.then(function() {
						self.showing = false;
					});
				}
				else{
					this.showing = false;
				}
			},
			showLoading() {
				Utils.showLoading('#'+this.modal_id_);
			},
			closeLoading() {
				Utils.closeLoading('#'+this.modal_id_);
			}
		}
	}
</script>
<style>
	.modal-rj {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
		width: 100%;
		height: 100%;
		background-color: #0008;
		transition: transform 0.3s ease-out;
	}
	.modal-dialog-rj {
		transition: transform 0.3s ease-out;
		max-width: 800px;
		flex-direction: column;
		justify-content: center;
		height: calc(100% - 3.5rem);
		display: flex;
		align-items: center;
		position: relative;
		width: auto;
		pointer-events: none;
		margin: 1.75rem auto;
	}
	.full-modal {
		max-width: unset;
		width: 100%!important;
		margin: 0!important;
		height: 100%!important;
	}
	.full-modal .modal-content-rj {
		width: calc(100% - 1.75rem)!important;
		height: 100%;
		margin: 1.75rem;
	}
	.small-modal {
		max-width: unset;
		width: 25%!important;
	}
	.small-modal .modal-content-rj {
		width: calc(100% - 1.75rem)!important;
		margin: 1.75rem;
	}
	.modal-content-rj {
		max-height: none;
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		outline: 0;
		border-radius: 0.75rem;
	}
	.modal-header-rj {
		justify-content: space-between;
		border-top-left-radius: calc(0.3rem - 1px);
		border-top-right-radius: calc(0.3rem - 1px);
		border-bottom: 1px solid #0000001A;
		padding: 0.1rem 0.1rem;
		margin: 25px 0 0 0;
	}
	.modal-header-rj>div {
		align-items: center;
		padding: 0.3rem 1rem;
	}
	.modal-body-rj {
		overflow-y: auto;
		padding: 2.2rem;
		position: relative;
		flex: 1 1 auto;
	}
	.modal-footer-rj {
		flex-shrink: 0;
		margin: 0 auto;
		border-top: 0px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		/*padding: 0.75rem;*/
		border-bottom-left-radius: calc(0.3rem - 1px);
		border-bottom-right-radius: calc(0.3rem - 1px);
	}
	.modal-footer-rj  > * {
		margin: 0.25rem;
	}
	.button-close-modal{
		margin: 25px 25px 0 0;
	}
	.button-close-modal i{
		font-size: 18px;
	}
	@media (max-width: 768px) {
		.modal-dialog-rj {
			height: 100%;
			margin: 0;
			z-index: 10001;
		}
		.modal-content-rj {
			height: 100%;
			border-radius: 0;
		}
		.modal-header-rj{
			padding: 2rem 0.1rem;
		}
	}
</style>
<style scoped>
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.5s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}
</style>