<template>
    <div>
        <header style="background-color: #F2F2F2!important">
            <nav class="navbar" role="navigation" id="slide-nav">
                <div class="container-fluid">
                    <a class="navbar-brand" href="https://rastreioja.com.br">
                        <div class="d-flex align-items-center">
                            <img alt="Brand" :src="logo" style="height: 100%;object-fit: contain;">
                        </div>
                    </a>
                </div>
            </nav>
        </header>
    </div>
</template>
<script>
import logo from '@/assets/images/rj_logo_2.png'

export default {
    name: "Header",
    data() {
        return {
            logo: logo
        }
    }
}
</script>
<style scoped>
header {
    background: #348bc2;
    height: 70px;
}

.navbar {
    background: transparent;
    padding: 5px!important;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
}

.navbar-brand {
    margin-left: 50px;
}

.navbar-brand img {
    width: 180px;
}

@media (min-width: 768px) {
    .navbar {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .navbar {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .navbar {
        width: 1170px;
    }
}

@media (min-width: 1200px) {
    .navbar {
        width: 970px;
    }
}

@media(max-width: 768px) {
    .navbar .navbar-brand {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
</style>