<template>
    <div class="landing-page">
        <Header />
        <div class="container" style="padding-top: 72px; text-align: justify;">
            <h1>{{ politic.title }}</h1>
            <div v-if="politic.content" v-html="politic.content">
            </div>
            <div class="counter">
                <div v-for="item in politic.body">
                    <h3>{{ item.section_title }}</h3>
                    <div v-if="item.content" v-html="item.content">
                    </div>
                    <div v-for="subsection in item.subsections">
                        <h4>{{ subsection.title }}</h4>
                        <div v-html="subsection.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import politic from "@/assets/files/politic.json"
import Header from "@/views/Landingpage/Header.vue"
import Footer from "@/views/Landingpage/Footer.vue"
export default {
    name: "Politic",
    components: { Header, Footer },
    data() {
        return {
            politic: {}
        }
    },
    mounted() {
        this.politic = politic
    }
}
</script>
<style scoped>
.landing-page {
    background: #FFF;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 970px;
    }
}

.counter {
    counter-reset: section;
}

h3 {
    counter-reset: subsection;
}

h3::before {
    counter-increment: section;
    content: counter(section) " - ";
}

h4::before {
    counter-increment: subsection;
    content: counter(section) "." counter(subsection) " ";
}
</style>