<template>
	<div>
		<div class="wrapper" style="height:100%">
			<div class="main-panel d-flex">
				<div class="sidebar-admin desktop-only">
					<div class="sidebar-admin-wrapper d-flex flex-column h-100">
						<div class="logo d-flex align-itens-center justify-content-start">
							<img class="navbar-brand" height="40px" :src="logo">
						</div>
						<div>

						</div>
						<div class="d-flex flex-column nav-menu">
							<div :class="{ 'route-active': isActive('accounts') }">			
								<router-link :to="{ name: 'accounts' }" class="d-flex justify-content-start w-100 h-100">
									<i class="fa-solid fa-clipboard-user"></i>
									<span class="nav-text">Contas</span>
								</router-link>
							</div>
							<div :class="{ 'route-active': isActive('stores') }">			
								<router-link :to="{ name: 'stores' }" class="d-flex justify-content-start w-100 h-100">
									<i class="fa-solid fa-shop"></i>
									<span class="nav-text">Lojas</span>
								</router-link>
							</div>
						</div>
						<div class="infos-admin d-flex flex-column align-items-center mt-auto">
							<hr style="width:90%">
							<div class="infos-content">
								<div>
									<div class="p-2">
										<i style="font-size: 30px" class="fa-solid fa-circle-user"></i>
									</div>
								</div>
								<div>
									<div v-if="name" class="text-nowrap text-container">
										<span ><strong>{{ name }}</strong></span>
									</div>
									<div v-if="emailUser" class="text-nowrap text-container">
										<span style="font-size: 10px"><strong>{{ emailUser }}</strong></span>
									</div>
								</div>
								<div>
									<div class="btn-logout">
										<button class="btn" title="Sair" data-bs-toggle="tooltip" v-on:click="logout()">
											<i class="fa-solid fa-right-from-bracket"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<transition name="fade">
						<router-view></router-view>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Button from '@/components/Button'
	import logo from '@/assets/images/rj_logo_1.png'
	import { currentUser } from '@/database/firebase';
	import * as LoginServices from '@/services/LoginServices.js' 

	export default {
		name: 'MainAdmin',
		components: {Button},

		data(){
			return {
				logo: logo,
				emailUser: null,
				name: null,
				tooltipList:[]
			}
		},
		mounted(){
			var self = this;
			if(!currentUser) {
				EventBus.$on('LOGGED-IN', (data)=>{
					self.emailUser = data.email;
					self.uid = data.uid;
					self.name = data.displayName;
					Utils.closeLoading();
				});
			} else {
				this.emailUser = currentUser.email;
				this.uid = currentUser.uid;
				Utils.closeLoading();
			}
			this.enableTooltips();
		},
		beforeDestroyed(){
			this.disposeTooltips();
		},
		methods:{
			isActive(route) {
				return this.$router.currentRoute.name == route;
			},
			logout(){
				let self = this;
				const logoutButton = document.querySelector('.btn-logout [data-bs-toggle="tooltip"]');
				if (logoutButton) {
					const tooltipInstance = bootstrap.Tooltip.getInstance(logoutButton);
					if (tooltipInstance) {
						tooltipInstance.dispose();  
					}
				}
				LoginServices.signOut().then(()=>{
				}).catch((error)=>{
					Utils.showDialogError(error);
					self.enableTooltips();
				})
			},
			enableTooltips() {
				if (!Utils.isMobile()) {
					this.$nextTick(() => {
						const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
						const options = {
							animation: true,
							trigger: 'hover',
						};

						this.tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => {
							const tooltipInstance = new bootstrap.Tooltip(tooltipTriggerEl, options);
							return tooltipInstance;
						});
					});
				}
			},
			disposeTooltips() {
				if (this.tooltipList.length > 0) {
					this.tooltipList.forEach((tooltip) => {
						if (tooltip) {
							tooltip.dispose();
						}
					});
					this.tooltipList = []; 
				}
			},
		},
	}
</script>
<style>
	.wrapper {
		height: 100%;
	}

	.main-panel {
		height: 100%;
	}

	.main-panel>.sidebar-admin {
		width: 300px;
		height: 100%;
		background-color: #f6f7f9
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.logo {
		padding: 10px;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu {
		overjlow-y: auto;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div {
		padding: 10px;
		margin: 10px;
		display: flex !important;
		border-radius: 8px;
		align-items: center;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div i {
		margin-right: 15px;
		color: #465b85;
		font-size: 20px;
		margin-left: 15px;
		width: 40px;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div .nav-text {
		color: #465b85;
		font-weight: 400;
		transition: color 0.2s, font-size 1s;
		letter-spacing: 0.5px;
		text-decoration: none;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div .nav-text:hover {
		color: #465b85;
		font-weight: 600;
		transition: color 0.2s, font-size 1s;
	}
	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div a {
		text-decoration: none;
	}
	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>div a:hover {
		text-decoration: none;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>.route-active {
		background-color: #73879ca8;
		color: #ffffffbe;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		transition: color 0.2s, font-size 1s, background 0.3s;

	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>.route-active .nav-text {
		color: #ffffffbe !important;
		transition: color 0.2s, font-size 1s;
		font-weight: 600;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.nav-menu>.route-active i {
		color: #ffffffbe !important;
	}

	.main-panel>.sidebar-admin>.sidebar-admin-wrapper>.infos-admin {
		margin-bottom: 10px;
	}

	.main-panel>.content {
		width: 100%;
		background-color: #FFF;
		height: 100%;
		overjlow-y: auto;
		padding-top: 30px;
	}

	.footerbar {
		position: fixed;
		width: 100% !important;
		bottom: 0;
		padding: 10px;
		background: linear-gradient(rgba(255, 233, 201, 0.8), rgba(255, 233, 201, 0.1));
		background-color: #FFF;
		border-radius: 20px;
	}

	.footerbar>div i {
		color: #b9780087;
		font-size: 20px;
	}

	.footerbar>div>.route-active i {
		color: var(--black-lm);
		font-size: 25px;
		transition: color 0.2s, font-size 1s,
	}

	.main-panel>.content>div:nth-child(2) {
		padding: 0px 15px;
	}
	.infos-admin .infos-content {
		display: flex;
		border-radius: 8px;
		background-color: #73879c4a;
		padding: 10px;
		width: -webkit-fill-available;
		margin: 10px;
		align-items: center;
		justify-content: space-between;
	}
	.infos-admin .infos-content .btn-logout button:hover {
		color: var(--red-rj);
	}
</style>
<style scoped>
	.fade-enter-active {
		transition: opacity 1.2s;
	}

	.fade-enter, .fade-leave-to  {
		opacity: 0;
	}
</style>