export function calculateValidUntil(dueDay, created_at, lastPayment = null) {
  if(!lastPayment) {
    lastPayment = moment(created_at);
    const dueDayDate = moment({ year: lastPayment.year(), month: lastPayment.month(), day: dueDay });
    if(dueDayDate.isBefore(lastPayment) || (dueDayDate.isSame(lastPayment, 'year') && dueDayDate.isSame(lastPayment, 'month') && dueDayDate.isSame(lastPayment, 'day'))) {
      lastPayment.add(1, 'month');
    }
  } else {
    lastPayment = moment(lastPayment);
  }
  let validUntil;

  // Check if the current month is less than December (11)
  if (lastPayment.month() < 11) {
    // If it's before December, set validUntil to the next month with the given due day
    validUntil = moment({ year: lastPayment.year(), month: lastPayment.month() + 1, day: dueDay });
  } else {
    // If it's December, set validUntil to January of next year with the given due day
    validUntil = moment({ year: lastPayment.year() + 1, month: 0, day: dueDay });
  }

  // Check if it's February (1 in 0-indexed month system) and the day is beyond February's limit (like 30th or 31st)
  if (validUntil.month() === 1 && validUntil.date() > 28) {
    // Adjust for leap years, set the date to the 1st of March
    validUntil.set({ month: 2, day: 1 });
  }

  // Set the seconds to 1 to avoid having 00:00:00 for the time
  validUntil.set({ second: 1 });

  return validUntil;
}