<template>
	<footer>
		<div class="container d-flex align-items-center">
			<div class="copyright">
				<span class="me-3">2024 Rastreio já. Todos os direitos reservados.</span>
				<a class="me-3" href="termos-de-uso">Termos de uso</a>
				<a class="me-3" href="politica">Política de privacidade</a>
			</div>
		</div>
	</footer>
</template>
<script>
	export default {
		name: "Footer"
	}
</script>
<style scoped>
	footer {
		background-color: #F2F2F2!important;
	}
	.container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		min-height: 50px;
	}
	.copyright span {
		color: var(--orange-rj);
	}
	.copyright a {
		color: var(--orange-rj);
        text-decoration: none;
	}
	.copyright a:hover {
		color: var(--bonina-rj);
		transition: all 0.3s;
	}
	.download {
		font-size: 21px;
		margin-left: auto;
	}
	.download a {
		margin-right: 0.5rem;
		color: #ddb0b0;
	}
	.download a:hover {
		color: #FFF;
		transition: all 0.3s;
	}
	@media (min-width: 768px){
		.container {
			width: 750px;
			font-size: 12px;
		}
		
	}
	@media (min-width: 992px){
		.container {
			width: 970px;
		}
	}
	@media (min-width: 1200px){
		.container {
			width: 1170px;
		}
	}
	@media (min-width: 1200px){
		.container {
			width: 970px;
		}
	}
	@media(max-width: 768px){
		footer .container {
			flex-direction: column!important;
			flex-wrap: wrap;
			justify-content: center;
		}
		footer .container .copyright {
			display: flex;
			flex-wrap: wrap;
			text-align: center;
			align-items: center;
			justify-content: center;
		}
		footer .container .copyright a {
			flex: 1 1 auto !important;
			margin-right: 0!important;
		}
		footer .container .download {
			margin-left: 0!important;
		}
	}
</style>