import { ref, onValue, query, get, orderByKey, limitToFirst, startAt, startAfter, onChildAdded, onChildChanged, onChildRemoved, off } from "firebase/database";
import { db, currentUser } from '@/database/firebase';

export function loadDrivers(addedListener, updatedListener, removedListener){
	const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver');
	onChildAdded(driverRef, (data) => {
		addedListener(data.key, data.val());
	});

	onChildChanged(driverRef, (data) => {
		updatedListener(data.key, data.val());
	});

	onChildRemoved(driverRef, (data) => {
		removedListener(data.key, data.val());
	});
}

export function loadLocations(addedListener, updatedListener, removedListener){
	const locationsRef = ref(db, 'stores/' + currentUser.uid + '/locations');
	onChildAdded(locationsRef, (data) => {
		addedListener(data.key, data.val());
	});

	onChildChanged(locationsRef, (data) => {
		updatedListener(data.key, data.val());
	});

	onChildRemoved(locationsRef, (data) => {
		removedListener(data.key, data.val());
	});
}

export function loadInformations(update){
	const infoRef = ref(db, 'stores/' + currentUser.uid + '/informations');
	onValue(infoRef, (snapshot) => {
		const data = snapshot.val();
		update(data)
	})
}

export function loadSubscription(update){
	const subref = ref(db, 'stores/' + currentUser.uid + '/subscription');
	onValue(subref, (snapshot) => {
		const data = snapshot.val();
		update(data)
	})
}

export function loadPayments(pageSize = 10, nextPageToken = null){
	return new Promise(async (resolve, reject)=>{
		var start = null;
		var pageQuery = query(ref(db, 'stores/' + currentUser.uid + '/billing'), orderByKey(), limitToFirst(pageSize));
		if(nextPageToken) {
			pageQuery = query(ref(db, 'stores/' + currentUser.uid + '/billing'), orderByKey(), startAfter(nextPageToken), limitToFirst(pageSize));
		}
		try {
			const snapshot = await get(pageQuery);
			if (snapshot.exists()) {
				const data = snapshot.val();

				let billing = Object.keys(data).map(function(key) {
					return {
						'created': data[key].created_at,
						'paymentReference': data[key].payment_reference,
						'valueInCents': Utils.formatMoney(data[key].value_in_cents/100),
						'uid': key,
						'pixCode': data[key].pix_code,

					}
				});

				resolve(billing);
			} else {
				resolve([]);
			}
		} catch (error) {
			reject(error)
		}

	})
}

export function removeListeners(){
	const driverRef = ref(db, 'stores/' + currentUser.uid + '/driver');
	const locationsRef = ref(db, 'stores/' + currentUser.uid + '/locations');
	off(driverRef);
	off(locationsRef);
}