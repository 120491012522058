<template>
	<div>
		<div class="ms-5 d-flex">
			<div><span class="page-title">Contas</span></div>
			<div class="ms-auto me-5">
				<Button color="green" title="Cadastrar conta" :line="true" @action="newAccount()" />
			</div>
		</div>
		<div class="mt-4">
			<FormInputSearch class="w-50" v-model="search" @updateModel="filter()" placeholder="Procure UID, Nome ou E-mail" />
		</div>
		<div class="mt-3">
			<GenericTable ref="GenericTable"
			identifier="accounts"
			:source="getSource()"
			:columns="columns"
			:tableClass="'table'"
			:tableStyle = '"font-size:14px"'
			:perPages = "[10, 20, 30]"
			/>
		</div>
		<ModalNewAccount ref="ModalNewAccount" />
	</div>
</template>
<script>
	import Button from '@/components/Button'
	import GenericTable from '@/components/GenericTable'
	import * as AccountServices from '@/services/AccountServices';
	import ModalNewAccount from './ModalNewAccount'
	import FormInputSearch from '@/components/FormInputSearch'

	export default {
		name: "Accounts",
		components: {Button, GenericTable, ModalNewAccount, FormInputSearch},

		data(){
			return {
				columns: [
					new Utils.GenericTableColumn('uid', "UID", false,),
					new Utils.GenericTableColumn('displayName', "Nome", false,),
					new Utils.GenericTableColumn('email', "E-mail", false,),
					new Utils.GenericTableColumn('admin', "Função", false,),
					new Utils.GenericTableActionColumn("",this.tableActions,null, this.tooltipsTable,'fa-regular fa-pen-to-square', 'fa-regular fa-trash-can')
					],
				accounts: [],
				nextPageToken: null,
				search: null
			}
		},

		methods:{
			getSource() {
				return this;
			},
			numOfRows() {
				return this.accounts.length;
			},
			templateForRow(column, r) {
				var row = this.accounts[r];
				if(column == 0) {
					return '<div><span style="text-align:center"><strong>' + row.uid + '</strong></span></div>';
				} else if(column == 1) {
					return '<div><span><strong>' + row.displayName + '</strong></span><div>';
				} else if(column == 2) {
					return '<div><span><strong>' + row.email + '</strong></span></div>';
				} else if(column == 3) {
					return '<div><span class="badge rounded-pill badge--green-rj">' + (row.role ? row.role : '') + '</span><div>';
				}
				return '';
			}, 
			loadData(page,perPage,sortColumn,sortOrder,resolve) {
				var self = this;
				AccountServices.loadAccounts(perPage, this.nextPageToken, this.search).then(function(result){
					self.accounts.push(...result.data.users);
					self.nextPageToken = result.data.nextPageToken;
					if(result.data.nextPageToken)
						resolve(result.data.users);
					else
						resolve();
				}).catch((err)=> {
					Utils.showDialogError(err.message);
				});
			},
			newAccount(account = null){
				let self = this;
				this.$refs.ModalNewAccount.showModal(account).then(()=>{
					self.filter();
				});
			},
			removeAccount(account) {
				let self = this;
				Utils.showDialogConfirm(`Deseja mesmo remover a conta <strong>${account.email}</strong>?`, 'Remover').then(()=>{
					Utils.showLoading();
					AccountServices.removeAccount(account.uid).then(function(result){
						self.filter();
						Utils.closeLoading();
						Utils.showSnackbarSuccess(result.data.message);
					}).catch((err)=> {
						Utils.closeLoading();
						Utils.showDialogError(err.message);
					});
				}).catch(()=>{})
			},
			tooltipsTable(button){
				if(button == 0){
					return 'Editar conta';
				} else if(button == 1) {
					return 'Excluir conta';
				}
			},
			tableActions(button, row) {
				if(button == 0) {
					this.newAccount(this.accounts[row]);
				} else if(button == 1) {
					this.removeAccount(this.accounts[row]);
				}
			},
			filter(){
				this.accounts = [];
				this.nextPageToken = null;
				this.$refs.GenericTable.refreshData();
			}
		}
	}
</script>